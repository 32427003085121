<script lang="ts" setup>
const { result } = await useAsyncAccountActivationCheck();

const showAlert = computed(() => {
  if (!result.value) return false;
  return result.value.isAuthenticated && !result.value.isActivated;
});
</script>

<template>
  <p v-if="showAlert" class="alert">
    メールを確認して<NuxtLink :to="{ path: '/settings/email' }" class="link"
      >アカウント認証</NuxtLink
    >をしてください
  </p>
</template>

<style lang="scss" scoped>
.alert {
  padding: 8px;
  background-color: $color-warning-surface;
  color: $color-warning-object;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
}

.link {
  color: inherit;
  text-decoration: underline;
}
</style>
