<script setup lang="ts">
import type { Notification } from '@/composables/useNotifications';

const props = defineProps<{
  notification: Omit<Notification, 'key'>;
}>();

const formattedDatetime = useFormattedDateTime(
  toRef(() => props.notification.datetime ?? null)
);
</script>

<template>
  <NuxtLink :href="notification.location" :class="$style.item">
    <div :class="$style.item__thumbnail">
      <img :src="notification.imageUrl" />
    </div>
    <p :class="$style.item__title">{{ notification.title }}</p>
    <p :class="$style.item__body">{{ notification.body }}</p>
    <p :class="$style.item__datetime">{{ formattedDatetime }}</p>
  </NuxtLink>
</template>

<style lang="scss" module>
.item {
  display: grid;
  box-sizing: border-box;
  padding: 16px;
  grid-template:
    'thumbnail . title   '
    'thumbnail . body    '
    'thumbnail . datetime'
    / 60px 12px 1fr;
  transition: background-color $transition-interaction;

  &:hover {
    cursor: pointer;
    background-color: $color-surface-tertiary;
    text-decoration: none;
  }

  &__thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: thumbnail;

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 30px;
    }
  }

  &__title {
    grid-area: title;
    font-size: 14px;
    color: $color-text-and-object-high-emphasis;
    font-weight: bold;
    margin: 0;
  }

  &__body {
    grid-area: body;
    font-size: 11px;
    color: $color-text-and-object-high-emphasis;
    margin: 0;
  }

  &__datetime {
    text-align: right;
    grid-area: datetime;
    font-size: 10px;
    color: $color-text-and-object-low-emphasis;
    margin: 0;
  }
}
</style>
