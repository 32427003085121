<script setup lang="ts">
import NotificationList from './NotificationList/NotificationList.vue';
import FloatingPanel from 'theme/components/molecules/FloatingPanel.vue';

const emit = defineEmits<{
  closeNotification: [];
}>();

const { notifications } = useNotifications();
</script>

<template>
  <FloatingPanel
    class="notifications"
    @close:floating-panel="emit('closeNotification')"
  >
    <NotificationList :notifications="notifications" />
  </FloatingPanel>
</template>
