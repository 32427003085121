<script setup lang="ts">
import NotificationListItem from './NotificationListItem.vue';

const { notifications } = useNotifications();

const hasNotifications = computed(() => {
  return notifications.value.length > 0;
});
</script>

<template>
  <div>
    <ul v-if="hasNotifications" :class="$style['list']">
      <li
        v-for="(notification, index) in notifications"
        :key="index"
        :class="[$style['list__item'], $style['list-item']]"
      >
        <NotificationListItem
          :notification="notification"
          :class="$style['list-item__notification']"
        />
      </li>
    </ul>
    <div v-else :class="[$style['list'], $style['list--empty']]">
      未読の通知はありません。
    </div>
  </div>
</template>

<style lang="scss" module>
.list {
  display: block;
  list-style: none;
  padding-left: 0;
  width: 100%;
  max-height: 50vh;
  overflow: auto;

  &--empty {
    display: flex;
    font-size: 12px;
    padding: 32px;
    align-items: center;
    justify-content: center;
    color: $color-text-and-object-low-emphasis;
  }

  &__item {
    &:nth-child(n + 2) {
      border-top: 1px solid $color-border-low-emphasis;
    }
  }
}
</style>
